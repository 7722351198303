<template>
  <div
    class="check-list"
    :class="themeColorClass"
  >
    <UiHeader
      v-if="header"
      class="check-list__heading"
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      :header-size="headerSize"
      :theme-color="themeColor"
    />

    <ul
      v-if="itemList.length"
      class="check-list__list"
    >
      <li
        v-for="item in itemList"
        :key="item.id"
        class="check-list__item"
      >
        <div class="check-list__item-header">
          <SvgIconCheck class="check-list__item-marker" />

          <T3HtmlParser
            v-if="item.name"
            :tag="item.text ? 'h3' : 'div'"
            class="check-list__item-title"
            :content="item.name"
          />
        </div>

        <T3HtmlParser
          v-if="item.text"
          class="check-list__item-text"
          :content="item.text"
        />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import SvgIconCheck from '~ui/assets/icons/icon-check.svg'
import { UiHeader } from '~ui/components'
import useTheming from '~ui/composables/useTheming'
import type { UiCeProductBoxesProps } from '~ui/types'

const props = defineProps<UiCeProductBoxesProps>()

const themeColorClass = useTheming(props.themeColor)
</script>

<style lang="scss">
.check-list {
  &__heading {
    margin-bottom: rem(60px);
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: rem(20px);
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: rem(12px);

    &-header {
      display: flex;
      align-items: center;
      gap: rem(8px);
    }

    &-marker {
      flex-shrink: 0;
      margin-bottom: rem(2px);

      & path {
        fill: var(--theme__theme-color);
      }
    }

    &-title {
      margin-block: auto;
      font-size: rem(20px);
      font-weight: 700;
      line-height: 1.2;
    }

    &-text {
      @include themed-link;
    }
  }
}
</style>
